<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import { getDateFormat, getContextId } from '@/util/utils';

import EzEntityInfo from '@/components/ui/EntityInfo';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzButton from '@/components/ui/Button';
import EzInput from '@/components/ui/Input';
import flash from '@/components/ui/FlashMessage';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

/**
 * AddNewStockModal
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'AddNewStockModal',
  props: {},
  components: {
    EzEntityInfo,
    EzFormModal,
    EzButton,
    EzInput,
    VSelectSearch,
  },
  data() {
    return {
      values: {
        product: {},
        countingOrder: '',
      },
      products: [],
      getDateFormat,
    };
  },
  computed: {
    ...mapState('entities/products', ['venueStocktake']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    venueId() {
      return getContextId();
    },
    location() {
      return this.venueStocktake.location;
    },
    disabled() {
      return !this.values.product.id || !this.values.countingOrder?.length;
    },
    loading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_PRODUCTS_ADD_INVENTORY);
    },
    productSelected() {
      return !!Object.keys(this.values.product).length;
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'venueFetchInventoryProducts',
      'venueUpdateInventoryProduct',
    ]),
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    async addStock() {
      try {
        const positions = this.values.countingOrder
          .replaceAll(' ', '')
          .split(',')
          .filter(item => item);
        const data = {
          addedPositions: positions,
          positionsLocationId: this.location.id,
        };

        await this.venueUpdateInventoryProduct({
          venueId: this.venueId,
          productId: this.values.product.id,
          body: data,
        });

        flash.success({ title: 'Inventory item successfully added.' });

        this.$emit('success', {
          positions,
          product: this.values.product,
        });
        this.close();
      } catch (err) {
        // do nothing
      }
    },
    onClose() {
      Object.keys(this.values).forEach(key => {
        if (key === 'product') this.values[key] = {};
        else this.values[key] = null;
      });
      this.expiryDateInvalid = false;
    },
    onInputChange(name, event) {
      if (name === 'locationId') this.values[name] = event.id;
      else this.values[name] = event;
    },
    onInvalidExpiryDate(value) {
      this.expiryDateInvalid = value;
    },
    removeSelectedProduct() {
      this.values.product = {};
    },
  },
  async created() {
    const { data } = await this.venueFetchInventoryProducts({ venueId: this.venueId, limit: 30 });
    this.products = data.data;
  },
};
</script>

<template>
  <ez-form-modal ref="modal" @close="onClose">
    <template #title>Add New Stock Item</template>
    <template #content>
      <div class="subheader">
        <p class="subheader__text">Select a product and set its counting order.</p>
        <hr />
      </div>

      <v-select-search
        v-if="!productSelected"
        label="Select the product to add to this stocktake"
        :search="`/venues/${venueId}/inventory-products`"
        placeholder="Select Product"
        searchPlaceholder="Search for a Product"
        :minLength="2"
        :data="products"
        :selected="values.product"
        @selected="onInputChange('product', $event)"
        isFullWidth
      >
        <template #result="{ result }">
          <ez-entity-info
            :imgUrl="result.image"
            imgWidth="2rem"
            imgHeight="2rem"
            imgBorderRadius="3px"
          >
            <span class="entity-info__name">{{ result.name }}</span>
            <span class="entity-info__sku">{{ result.sku }}</span>
          </ez-entity-info>
        </template>
      </v-select-search>

      <ez-entity-info v-else class="selected-product" :imgUrl="values.product.image">
        <div class="product-info" :title="values.product.name">
          <span class="product-info__name">{{ values.product.name }}</span>
          <span class="product-info__sku">{{ values.product.sku }}</span>
        </div>
        <ez-button type="link" class="selected-product__remove" @click="removeSelectedProduct">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <!-- eslint-disable-next-line max-len -->
            <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"
            />
          </svg>
        </ez-button>
      </ez-entity-info>
      <ez-input
        form-key=""
        name="countingOrder"
        label="Counting Order"
        class="mask-input mt-16"
        placeholder="Add Order"
        :value="values.countingOrder"
        @onChange="onInputChange('countingOrder', $event)"
        :validators="{ regex: /^[0-9,.\s]*$/ }"
      />
    </template>
    <template #footer>
      <ez-button type="link" @click="close"> Cancel </ez-button>
      <ez-button :disabled="disabled" :isLoading="loading" @click="addStock"> Add Stock </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.subheader {
  &__text {
    @include font-size(14px, 20px);
    margin-top: 0;
  }
}

.selected-product {
  padding: 16px 12px;
  border-radius: 3px;
  background-color: $color-white;
  box-shadow: 0 1px 1px 0 #dee1e4;
  border: 1px solid #e9ebf2;

  &__remove {
    height: 32px;

    svg {
      @include size(10px);
      fill: #6c7995;
    }
  }

  :deep() .entity-info__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .product-info {
    display: flex;
    flex-direction: column;

    &__name {
      @include font-size(14px, 20px);
      color: $color-gray-25;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__sku {
      @include font-size(12px, 14px);
      color: $color-gray-6C;
      font-weight: 500;
    }
  }
}

.mask-input {
  :deep() .mask-input__input {
    font-weight: normal;
  }

  &.empty-date :deep() .placeholder {
    color: $color-gray-6C;
  }

  :deep() .ez-select__dropdown .ez-option {
    @include font-size(14px);
  }
}

:deep() .select-search {
  &__trigger span {
    @include font-size(14px, 20px);
  }

  .entity-info {
    align-items: center;

    &__text {
      flex-direction: column;
      align-items: start;
    }

    &__name {
      @include font-size(14px, 20px);
      font-weight: 600;
      color: $color-gray-25;
    }

    &__sku {
      @include font-size(12px, 14px);
      font-weight: 500;
      color: $color-gray-6C;
    }
  }
}
</style>
