<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { getContextId } from '@/util/utils';
import { wizardListenerMixin } from '@/mixins/wizard';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import uuid from 'uuid/v4';
import EzButton from '@/components/ui/Button';
import EzInput from '@/components/ui/Input';
import StatusBadge from '@/views/common/status-badge/StatusBadge.vue';

/**
 * Location
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Location',
  components: { VSelectSearch, EzButton, EzInput, StatusBadge },
  props: {
    stocktakeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      locations: [],
      location: null,
      users: [],
    };
  },
  computed: {
    ...mapState('entities/products', ['venueStocktake']),
    ...mapState('entities/users', ['loggedUser']),
    venueId() {
      return getContextId();
    },
  },
  methods: {
    ...mapActions('entities/venues', [
      'fetchVenueLocations',
      'venueFetchStocktake',
      'venueUpdateStocktake',
    ]),
    ...mapMutations('entities/products', [
      'UPDATE_VENUE_STOCKTAKE_DRAFT',
      'CLEAR_DRAFT',
      'UPDATE_VENUE_STOCKTAKE_LOCATION_USERS',
    ]),
    async onNextStep() {
      const { data } = await this.venueUpdateStocktake({
        venueId: this.venueId,
        stocktakeId: this.stocktakeId,
        body: {
          locationId: this.location.id,
          users: this.users.map(item => item.name),
        },
      });
      this.UPDATE_VENUE_STOCKTAKE_LOCATION_USERS({ location: this.location, data: data.data });
      this.$emit('stepCompleted');
    },
    onUserNameChange(idx, val) {
      this.users.splice(idx, 1, { ...this.users[idx], name: val.trim() });
    },
    addUser() {
      this.users = [...this.users, { id: uuid(), name: '' }];
    },
    removeUser(idx) {
      this.users.splice(idx, 1);
    },
  },
  watch: {
    location: {
      immediate: true,
      handler(val) {
        if (!val || !Object.keys(val).length) {
          this.disableNextStep();
          this.users = [];
        } else {
          this.users = this.venueStocktake.locationStocktakes
            .filter(item => item.location.id === this.location.id)[0]
            .users.map(item => ({ id: uuid(), name: item }));
          const currentUserIndex = this.users.findIndex(item => item.name === this.loggedUser.name);
          this.users.splice(currentUserIndex, 1);
          this.enableNextStep();
        }
      },
    },
  },
  async created() {
    this.CLEAR_DRAFT();
    const { data: stocktakeData } = await this.venueFetchStocktake({
      venueId: this.venueId,
      stocktakeId: this.stocktakeId,
    });
    this.UPDATE_VENUE_STOCKTAKE_DRAFT(stocktakeData.data);

    if (this.venueStocktake.location) this.location = this.venueStocktake.location;

    this.locations = stocktakeData.data.locationStocktakes.map(item => ({
      ...item.location,
      status: item.status,
    }));
  },
};
</script>

<template>
  <div>
    <v-select-search
      name="location"
      label="Location Name"
      placeholder="Choose Location"
      :hasClear="false"
      :data="locations"
      :selected="location"
      @selected="location = $event"
      isFullWidth
    >
      <template #result="{ result }">
        <div class="location-option">
          <span>{{ result.name }}</span> <status-badge :status="result.status" />
        </div>
      </template>
    </v-select-search>
    <div class="users-section__user mt-24" v-for="(user, idx) in users" :key="user.id">
      <ez-input
        formKey=""
        label="Stocktaker"
        :name="`user-${user.id}`"
        :value="user.name"
        @onChange="onUserNameChange(idx, $event)"
      />
      <ez-button class="users-section__remove" type="link" @click="removeUser(idx)">
        <font-awesome-icon icon="times" /> Remove
      </ez-button>
    </div>

    <ez-button type="secondary" class="add-user-button mt-16" @click="addUser" isFullWidth>
      <font-awesome-icon icon="plus" />
      Add User
    </ez-button>
  </div>
</template>

<style lang="scss" scoped>
:deep() .select-search {
  &__trigger span {
    @include font-size(14px, 20px);

    &:not(.select-search__value) {
      color: $color-gray-6C;
    }
  }

  &__search-wrapper {
    display: none;
  }

  &__item {
    @include font-size(14px, 20px);
  }
}

.users-section {
  &__user {
    position: relative;
  }

  &__remove {
    @include font-size(12px, 18px);
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
  }

  .add-user-button:disabled {
    background-color: #f5f6fa;
    color: rgba($color-gray-6C, 0.48);
  }
}

.location-option {
  display: flex;
  align-items: center;
  justify-content: space-between;

  :deep() .status {
    border-radius: 8px;
    padding: 0 8px;
  }
}
</style>
