import { render, staticRenderFns } from "./AddNewStockModal.vue?vue&type=template&id=43c1bb10&scoped=true&"
import script from "./AddNewStockModal.vue?vue&type=script&lang=js&"
export * from "./AddNewStockModal.vue?vue&type=script&lang=js&"
import style0 from "./AddNewStockModal.vue?vue&type=style&index=0&id=43c1bb10&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43c1bb10",
  null
  
)

export default component.exports