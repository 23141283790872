<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { LOADING_KEY } from '@/util/constants';
import { getDateFormat } from '@/util/utils';

import EzEntityInfo from '@/components/ui/EntityInfo';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzDateInput from '@/components/ui/DateInput';
import EzMaskInput from '@/components/ui/MaskInput';
import EzButton from '@/components/ui/Button';
import EzSelect from '@/components/ui/Select';
import EzInput from '@/components/ui/Input';
import flash from '@/components/ui/FlashMessage';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

/**
 * AddNewStockModal
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'AddNewStockModal',
  props: {},
  components: {
    EzEntityInfo,
    EzFormModal,
    EzDateInput,
    EzMaskInput,
    EzButton,
    EzSelect,
    EzInput,
    VSelectSearch,
  },
  data() {
    return {
      values: {
        product: {},
        locationId: null,
        batchCode: null,
        expiryDate: null,
        cogs: 0,
      },
      expiryDateInvalid: false,
      products: [],
      supplierCy,
      getDateFormat,
    };
  },
  computed: {
    ...mapState('entities/products', ['stocktake']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    me() {
      return this.loggedUser.distributor;
    },
    today() {
      return dayjs()
        .startOf('day')
        .add(1, 'day');
    },
    locations() {
      return [
        { id: null, code: 'Select Product Location' },
        ...(this.stocktake.warehouse?.locations || []),
      ];
    },
    batchCodeValid() {
      return this.me.inventorySettings?.batchTracking ? !!this.values.batchCode?.trim() : true;
    },
    disabled() {
      return (
        !this.values.product.id
        || !this.values.locationId
        || !this.batchCodeValid
        || this.expiryDateInvalid
      );
    },
    loading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_PRODUCTS_ADD_INVENTORY);
    },
    productSelected() {
      return !!Object.keys(this.values.product).length;
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'fetchDistributorProducts',
      'distributorProductsAddInventory',
    ]),
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    async addStock() {
      try {
        const data = {
          code: this.values.batchCode || null,
          warehouseId: this.stocktake.warehouse.id,
          items: [
            {
              productId: this.values.product.id,
              locationId: this.values.locationId,
              batchCode: this.values.batchCode || null,
              expiryDate: this.values.expiryDate
                ? dayjs(this.values.expiryDate).format('YYYY-MM-DD')
                : null,
              quantity: 0,
              cogs: this.values.cogs || 0,
            },
          ],
        };

        await this.distributorProductsAddInventory({ data });

        flash.success({ title: 'Inventory item successfully added.' });

        this.$emit('success');
        this.close();
      } catch (err) {
        // do nothing
      }
    },
    onClose() {
      Object.keys(this.values).forEach((key) => {
        if (key === 'product') this.values[key] = {};
        else this.values[key] = null;
      });
      this.expiryDateInvalid = false;
    },
    onInputChange(name, event) {
      if (name === 'locationId') this.values[name] = event.id;
      else this.values[name] = event;
    },
    onInvalidExpiryDate(value) {
      this.expiryDateInvalid = value;
    },
    removeSelectedProduct() {
      this.values.product = {};
    },
  },
  async created() {
    const { data } = await this.fetchDistributorProducts({ sortBy: 'name' });
    this.products = [...data.data];
  },
};
</script>

<template>
  <ez-form-modal ref="modal" @close="onClose">
    <template #title>Add New Stock</template>
    <template #content>
      <div class="subheader">
        <p class="subheader__text">
          Select a product and set its location, batch ID, and expiry date.
        </p>
        <hr />
      </div>

      <v-select-search
        v-if="!productSelected"
        label="Select the product to add to this stocktake"
        search="/distributor/search/products"
        placeholder="Select Product"
        searchPlaceholder="Search for a Product"
        :minLength="2"
        :data="products"
        :selected="values.product"
        @selected="onInputChange('product', $event)"
        isFullWidth
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.ADD_STOCK_MODAL.BUTTON__SELECT_PRODUCT"
      >
        <template #result="{ result }">
          <ez-entity-info
            :imgUrl="result.image"
            imgWidth="2rem"
            imgHeight="2rem"
            imgBorderRadius="3px"
          >
            <span class="entity-info__name">{{ result.name }}</span>
            <span class="entity-info__sku">{{ result.sku }}</span>
          </ez-entity-info>
        </template>
      </v-select-search>

      <ez-entity-info v-else class="selected-product" :imgUrl="values.product.image">
        <div class="product-info" :title="values.product.name">
          <span class="product-info__name">{{ values.product.name }}</span>
          <span class="product-info__sku">{{ values.product.sku }}</span>
        </div>
        <ez-button type="link" class="selected-product__remove" @click="removeSelectedProduct">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <!-- eslint-disable-next-line max-len -->
            <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z" />
          </svg>
        </ez-button>
      </ez-entity-info>

      <ez-select
        class="mask-input mt-16"
        name="locationId"
        label="Location"
        nameField="code"
        :value="values.locationId"
        :selected="values.locationId"
        :options="locations"
        @change="onInputChange('locationId', $event)"
        isFullWidth
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.ADD_STOCK_MODAL.BUTTON__SELECT_LOCATION"
      />
      <ez-input
        form-key=""
        name="batchCode"
        label="Batch ID"
        class="mask-input mt-16"
        placeholder="Enter Batch ID"
        :value="values.batchCode"
        @onChange="onInputChange('batchCode', $event)"
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.ADD_STOCK_MODAL.INPUT__BATCH_CODE"
      />
      <ez-date-input
        form-key=""
        name="expiryDate"
        label="Expiry Date"
        :placeholder="getDateFormat()"
        :date-format="getDateFormat()"
        :class="['mask-input', 'mt-16', { 'empty-date': !values.expiryDate }]"
        :min="today"
        v-model="values.expiryDate"
        @onChange="onInputChange('expiryDate', $event)"
        @invalid="onInvalidExpiryDate"
        is-tooltip-error
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.ADD_STOCK_MODAL.INPUT__EXPIRY_DATE"
      />
      <ez-mask-input
        form-key=""
        type="input"
        name="cogs"
        label="Cost of Goods Sold (COGS)"
        class="mask-input mt-16"
        :value="values.cogs || 0"
        :allow-negative-value="false"
        @input="onInputChange('cogs', $event)"
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.ADD_STOCK_MODAL.INPUT__COGS"
      />
    </template>
    <template #footer>
      <ez-button
        type="link"
        @click="close"
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.ADD_STOCK_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        :disabled="disabled"
        :isLoading="loading"
        @click="addStock"
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.ADD_STOCK_MODAL.BUTTON__SAVE"
      >
        Add Stock
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.subheader {
  &__text {
    @include font-size(14px, 20px);
    margin-top: 0;
  }
}

.selected-product {
  padding: 16px 12px;
  border-radius: 3px;
  background-color: $color-white;
  box-shadow: 0 1px 1px 0 #dee1e4;
  border: 1px solid #e9ebf2;

  &__remove {
    height: 32px;

    svg {
      @include size(10px);
      fill: #6c7995;
    }
  }

  :deep() .entity-info__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .product-info {
    display: flex;
    flex-direction: column;

    &__name {
      @include font-size(14px, 20px);
      color: $color-gray-25;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__sku {
      @include font-size(12px, 14px);
      color: $color-gray-6C;
      font-weight: 500;
    }
  }
}

.mask-input {
  :deep() .mask-input__input {
    font-weight: normal;
  }

  &.empty-date :deep() .placeholder {
    color: $color-gray-6C;
  }

  :deep() .ez-select__dropdown .ez-option {
    @include font-size(14px);
  }
}

:deep() .select-search {
  &__trigger span {
    @include font-size(14px, 20px);
  }

  .entity-info {
    align-items: center;

    &__text {
      flex-direction: column;
      align-items: start;
    }

    &__name {
      @include font-size(14px, 20px);
      font-weight: 600;
      color: $color-gray-25;
    }

    &__sku {
      @include font-size(12px, 14px);
      font-weight: 500;
      color: $color-gray-6C;
    }
  }
}
</style>
