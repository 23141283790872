<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import uuid from 'uuid/v4';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { clone, getContextId } from '@/util/utils';
import { wizardListenerMixin } from '@/mixins/wizard';
import { LOADING_KEY } from '@/util/constants';

import EzTextarea from '@/components/ui/Textarea';
import EzLoader from '@/components/ui/Loader';
import EzButton from '@/components/ui/Button';
import EzTable from '@/components/ui/Table';
import EzInput from '@/components/ui/Input';
import flash from '@/components/ui/FlashMessage';
import Paper from '@/components/layout/WizardOverviewPaper.vue';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo.vue';
import VTag from '@/components/v3/elements/VTag';
import { STOCKTAKE_STEP_1, STOCKTAKE_STEP_2 } from './steps';

/**
 * Overview
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Overview',
  components: {
    EzTextarea,
    EzLoader,
    EzButton,
    EzTable,
    EzInput,
    Paper,
    EzEntityInfo,
    VTag,
  },
  props: {
    stocktakeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      users: [],
      note: null,
      addUserDisabled: false,
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/products', ['venueStocktake']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    location() {
      return this.venueStocktake.location;
    },
    venueId() {
      return getContextId();
    },
    selectedProducts() {
      const products = [];
      this.venueStocktake.selectedProducts.forEach(item => {
        const index = products.findIndex(prod => prod.product.id === item.product.id);
        if (index === -1) {
          products.push({
            ...item,
            positions: [item.position],
          });
        } else {
          products[index] = {
            ...products[index],
            positions: [...products[index].positions, item.position],
            quantity: products[index].quantity + item.quantity,
          };
        }
      });
      return products;
    },
    loading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_CONDUCT_STOCKTAKE);
    },
    listProducts() {
      if (!this.selectedProducts.length > 10) return this.selectedProducts.slice(0, 10);
      return this.selectedProducts;
    },
  },
  methods: {
    ...mapActions('entities/venues', ['venueConductStocktake']),
    ...mapMutations('entities/products', ['CLEAR_DRAFT', 'UPDATE_STOCKTAKE_DRAFT']),
    editWarehouse() {
      this.$emit('stepBack', STOCKTAKE_STEP_1);
    },
    editProducts() {
      this.$emit('stepBack', STOCKTAKE_STEP_2);
    },
    async onNextStep() {
      try {
        const data = {
          note: this.note,
          users: this.users.map(u => u.name.trim()).filter(Boolean),
        };

        await this.venueConductStocktake({
          venueId: this.venueId,
          stocktakeId: this.stocktakeId,
          locationId: this.location.id,
          body: data,
        });

        this.CLEAR_DRAFT();
        flash.success({ title: 'Stocktake conducted successfully.' });

        await this.$router.push({ name: 'venue-stock-stocktake' });
      } catch (e) {
        // do nothing
      }
    },
    addUser() {
      this.users = [...this.users, { id: uuid(), name: '' }];
    },
    removeUser(idx) {
      this.users.splice(idx, 1);
    },
    onUserNameChange(idx, val) {
      this.users.splice(idx, 1, { ...this.users[idx], name: val.trim() });
    },
    onNoteChange(val) {
      this.note = val;
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
  },
  created() {
    this.enableNextStep();

    const stocktakeUsers = this.venueStocktake.locationStocktakes
      .filter(item => item.location.id === this.venueStocktake.location.id)[0]
      .users.map(item => ({ id: uuid(), name: item }));
    if (stocktakeUsers.length) {
      this.users = [{ id: this.loggedUser.id, name: this.loggedUser.name }, ...stocktakeUsers];
    } else this.users = [{ id: this.loggedUser.id, name: this.loggedUser.name }];

    this.note = this.venueStocktake.note || '';
  },
  watch: {
    users(val) {
      this.addUserDisabled = val.some(u => !u.name.trim());
      this.UPDATE_STOCKTAKE_DRAFT({ users: clone(val) });
    },
    note(val) {
      this.UPDATE_STOCKTAKE_DRAFT({ note: val });
    },
  },
};
</script>

<template>
  <div class="ez-inventory-overview">
    <hr class="mobile mb-12 mt-0" />
    <paper class="products-section">
      <template #title>Count ({{ selectedProducts.length }})</template>
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editProducts"
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.BUTTON__EDIT_PRODUCTS"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <template>
        <ez-table
          class="products-table"
          v-if="listProducts"
          :data="listProducts"
          :columns="['name', 'order', 'quantity']"
          :hasMinHeight="false"
          :headers="{
            name: () => 'Product',
            quantity: () => 'Current Count',
          }"
          :columnProps="{
            name: { class: 'name-cell' },
            quantity: { class: 'quantity-cell medium-cell' },
            order: { class: 'large-cell' },
          }"
          disableHover
        >
          <template
            #cell-name="{
              row: {
                product: { image, name, sku, orderingUnit },
              },
            }"
          >
            <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="image">
              <div class="product-info" :title="name">
                <span>
                  {{ name }}
                </span>
                <span class="product-info-secondary">
                  <span class="product-info-secondary--category">
                    {{ orderingUnit.label }}
                  </span>
                  <span v-if="sku" class="product-info-secondary--sku"> &#8226; {{ sku }} </span>
                </span>
              </div>
            </ez-entity-info>
          </template>
          <template #cell-order="{ row }">
            <v-tag
              color="#983BBD"
              backgroundColor="#EEDFF4"
              class="ml-4"
              v-for="(pos, idx) in row.positions"
              :key="idx"
              >{{ pos }}</v-tag
            >
          </template>
        </ez-table>
        <div class="mobile-products-list">
          <div
            v-for="product in listProducts"
            :key="product.id"
            class="product-item"
            :id="`product-item-${product.id}`"
          >
            <div class="first-row">
              <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="product.product.image">
                <div class="product-info" :title="product.product.name">
                  <span>
                    {{ product.product.name }}
                  </span>
                  <span class="product-info-secondary">
                    <span class="product-info-secondary--category">
                      {{ product.product.distributor.name }}
                    </span>
                  </span>
                </div>
              </ez-entity-info>
              <div class="product-quantity">
                <span>{{ product.quantity ?? '-' }}</span>
                <span class="product-info-secondary--category">
                  {{ product.product.orderingUnit.label }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="u-flex-h-center mt-16">
          <ez-button @click="editProducts" type="link" formType="button">
            <span>View Full List</span>
          </ez-button>
        </div>
      </template>
    </paper>
    <hr class="mobile" />

    <div class="sidebar-section">
      <paper>
        <template #title>Locations</template>
        <template>
          <div class="overview-warehouses">
            <div class="overview-warehouses__warehouse">
              <div class="warehouse-section">
                <ez-input
                  name="location"
                  label="Location Name"
                  :value="location.name"
                  :disabled="true"
                  formKey="overview"
                />
              </div>
            </div>
          </div>
        </template>
      </paper>

      <paper class="users-section" v-if="users.length">
        <template #title>Users</template>
        <template>
          <div class="users-section__user mt-12" v-for="(user, idx) in users" :key="user.id">
            <ez-input
              formKey=""
              label="Counter"
              :name="`user-${user.id}`"
              :value="user.name"
              :disabled="!idx"
              @onChange="onUserNameChange(idx, $event)"
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.INPUT__USER_NAME}-${user.id}`"
            />
            <ez-button
              v-if="idx"
              class="users-section__remove"
              type="link"
              @click="removeUser(idx)"
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.BUTTON__REMOVE_USER}-${user.id}`"
            >
              <font-awesome-icon icon="times" /> Remove
            </ez-button>
          </div>

          <ez-button
            type="secondary"
            class="add-user-button mt-16"
            @click="addUser"
            isFullWidth
            :disabled="addUserDisabled"
            :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.BUTTON__ADD_USER"
          >
            <font-awesome-icon icon="plus" />
            Add User
          </ez-button>
        </template>
      </paper>
      <paper class="note-section">
        <template #title>Note</template>
        <template>
          <ez-textarea
            formKey=""
            label=""
            name="note"
            placeholder="Leave a Note (Optional)"
            :value="note"
            @onChange="onNoteChange"
            :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.TEXTAREA__NOTE"
          />
        </template>
      </paper>
    </div>

    <ez-loader :show="loading" />
  </div>
</template>

<style lang="scss" scoped>
.ez-inventory-overview {
  display: flex;

  .products-section {
    max-width: initial;
    margin-right: 16px;
    height: fit-content;
  }

  .sidebar-section {
    width: 400px;
  }
}
.overview-warehouses {
  &__warehouse {
    .warehouse-section {
      &__label {
        @include font-size(12px, 18px);
        margin: 0 0 4px;
        color: $color-gray-6C;
        font-weight: 600;
      }

      &__text {
        @include font-size(14px, 20px);
        margin: 0;
      }
    }
  }
}

.users-section {
  &__user {
    position: relative;
  }

  &__remove {
    @include font-size(12px, 18px);
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
  }

  .add-user-button:disabled {
    background-color: #f5f6fa;
    color: rgba($color-gray-6C, 0.48);
  }
}

.products-table tbody tr td {
  :deep(.entity-info__text) {
    white-space: nowrap;
  }

  &.name-cell {
    .product-info {
      display: flex;
      flex-direction: column;

      .product-info-secondary {
        display: block;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.mobile-products-list {
  display: none;

  .product-item {
    margin-bottom: 8px;
  }

  .first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.5;

    .entity-info {
      align-items: center;
      min-width: 0;
      flex-shrink: 1;
    }

    .product-quantity {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: $color-gray-6C;
      font-size: 14px;
      flex-shrink: 0;

      .product-info-secondary--category {
        font-size: 12px;
      }
    }
  }

  .product-info {
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;

    .product-info-secondary {
      display: block;
      font-size: 12px;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

hr.mobile {
  display: none;
}
@media (max-width: 768px) {
  .ez-inventory-overview {
    flex-direction: column;

    .products-section,
    .sidebar-section {
      width: 100%;
      margin: 0 auto;
    }
    .sidebar-section {
      margin-top: 12px;
    }
  }

  :deep() .products-table {
    display: none;
  }

  .mobile-products-list {
    display: block;
  }

  .overview-paper {
    padding: 0;
    border: none;
    box-shadow: none;
  }

  hr.mobile {
    display: block;
  }
  .overview-paper {
    max-width: none;
  }
  :deep() .overview-paper__title .title-prefix h4 {
    font-size: 16px;
    line-height: 1.5;
    color: $color-gray-25;
    font-weight: 500;
    text-transform: none;
  }
}
</style>
