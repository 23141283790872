var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-step"},[_c('div',{staticClass:"subheader mb-24 u-flex-space"},[_c('ez-filter-list',{attrs:{"filters":_vm.filters},on:{"resetFilter":_vm.resetFilters,"filterUpdated":_vm.updateFilters}},[_c('ez-input',{staticClass:"search",attrs:{"formKey":"filters","label":"search","name":"search","placeholder":"Search for a Product","data-cy":_vm.supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.INPUT__SEARCH},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"search"}})]},proxy:true}])})],1),_c('p',{staticClass:"subheader__selected-count"},[_vm._v(" Products selected: "),_c('ez-badge',{attrs:{"type":"blue","count":_vm.selectedProducts.length || 0,"showEveryNumber":""}})],1),_c('div',{staticClass:"subheader__add-new"},[_c('ez-button',{attrs:{"data-cy":_vm.supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__ADD_STOCK},on:{"click":_vm.openAddNewStockModal}},[_vm._v(" Add New Stock ")])],1)],1),_c('ez-table',{staticClass:"products-table",attrs:{"disableHover":"","loading":_vm.loading,"data":_vm.products,"columns":[
      'name',
      'location',
      'batchCode',
      'currentInventory',
      'expiryDate',
      'quantity',
      'unit',
      'cogs',
      'actions',
    ],"headers":{
      name: () => 'Product',
      currentInventory: () => 'Stock',
      batchCode: () => 'Batch ID',
      quantity: () => 'Actual Inventory',
      cogs: () => 'COGS',
    },"columnProps":{
      name: { class: 'name-cell' },
      location: { class: 'medium-cell location-cell' },
      batchCode: { class: 'batchCode-cell' },
      expiryDate: { class: 'medium-cell' },
      quantity: { class: 'quantity-cell medium-cell' },
      unit: { class: 'medium-cell unit-cell' },
      cogs: { class: 'small-cell text-right-cell' },
      actions: { class: 'actions-cell' },
      currentInventory: { class: 'currentInventory-cell' },
    }},scopedSlots:_vm._u([{key:"cell-name",fn:function({
        row: {
          product: { image, name, sku },
        },
      }){return [_c('ez-entity-info',{attrs:{"imgWidth":"2rem","imgHeight":"2rem","imgUrl":image}},[_c('div',{staticClass:"product-info",attrs:{"title":name}},[_c('span',[_vm._v(_vm._s(name))]),_c('span',{staticClass:"product-info-secondary"},[_vm._v(_vm._s(sku))])])])]}},{key:"cell-batchCode",fn:function({ row: { batchCode } }){return [(batchCode)?_c('span',{attrs:{"title":batchCode}},[_vm._v(_vm._s(batchCode))]):_c('span',[_vm._v("-")])]}},{key:"cell-location",fn:function({ row: { location } }){return [_c('v-warehouse-location',{attrs:{"data":location ? [location.code] : []}})]}},{key:"cell-expiryDate",fn:function({ row: { expiryDate } }){return [(expiryDate)?_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.dayjs(expiryDate))))]):_c('span',[_vm._v("-")])]}},{key:"cell-quantity",fn:function({ row }){return [_c('ez-mask-input-simple',{attrs:{"formKey":"","name":"quantity","precision":_vm.isFractional(row) ? 2 : 0,"value":_vm.findSelected(row.id, 'quantity'),"minValue":0,"isInvalid":_vm.isInvalidQuantity[row.id],"data-cy":`${_vm.supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.INPUT__INVENTORY}-${row.id}`,"has-currency":false},on:{"input":function($event){return _vm.onInputChange('quantity', row, $event)},"invalid":function($event){return _vm.onInvalidQuantity(row, $event)},"click":function($event){$event.stopPropagation();}}})]}},{key:"cell-unit",fn:function({
        row: {
          product: { orderingUnit },
        },
      }){return [(orderingUnit)?[_c('v-data-with-info',{attrs:{"info":orderingUnit.type === _vm.packUnitType
              ? 'The quantity of pack unit products ' + 'is being managed per single unit.'
              : '',"show-underline":orderingUnit.type === _vm.packUnitType}},[_vm._v(" "+_vm._s(orderingUnit.label)+" ")])]:_vm._e()]}},{key:"cell-cogs",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("price")(row.cogs || 0))+" ")]}},{key:"cell-actions",fn:function({ row }){return [(_vm.isSelected(row.id))?_c('ez-button',{attrs:{"type":"green","data-cy":`${_vm.supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__DESELECT}-${row.id}`},on:{"click":function($event){return _vm.unselectProduct(row.id)}}},[_vm._v(" Selected ")]):_c('ez-button',{attrs:{"type":"secondary","data-cy":`${_vm.supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__SELECT}-${row.id}`},on:{"click":function($event){return _vm.selectProduct(row)}}},[_vm._v(" Select ")]),_c('ez-button-dropdown',{staticClass:"ml-16",attrs:{"buttonType":"secondary","data-cy":`${_vm.supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__OPTIONS}-${row.id}`},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"ellipsis-h"}})]},proxy:true},{key:"dropdown",fn:function(){return [_c('ez-button',{attrs:{"type":"link","disabled":!_vm.canEditStock(row),"data-cy":`${_vm.supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__EDIT_STOCK}-${row.id}`},on:{"click":function($event){return _vm.openEditStockModal(row)}}},[_vm._v(" Edit Stock ")])]},proxy:true}],null,true)})]}}])}),(_vm.loadingMore)?_c('div',{staticClass:"u-text-center mt-12"},[_c('ez-spinner')],1):_vm._e(),(!_vm.loadingMore && _vm.meta.nextId)?_c('ez-load-more',{on:{"loadMore":_vm.loadMoreProducts}}):_vm._e(),_c('add-new-stock-modal',{ref:"addNewStockModal",on:{"success":_vm.onAddNewStockSuccess}}),_c('edit-stock-modal',{ref:"editStockModal",on:{"success":_vm.onEditStockSuccess}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }