<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { clone, getDateFormat } from '@/util/utils';
import { LOADING_KEY } from '@/util/constants';

import EzEntityInfo from '@/components/ui/EntityInfo';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzDateInput from '@/components/ui/DateInput';
import EzMaskInput from '@/components/ui/MaskInput';
import EzTextarea from '@/components/ui/Textarea';
import EzButton from '@/components/ui/Button';
import EzSelect from '@/components/ui/Select';
import EzInput from '@/components/ui/Input';
import flash from '@/components/ui/FlashMessage';

/**
 * EditStockModal
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'EditStockModal',
  components: {
    EzEntityInfo,
    EzFormModal,
    EzDateInput,
    EzMaskInput,
    EzTextarea,
    EzButton,
    EzSelect,
    EzInput,
  },
  data() {
    return {
      values: {
        id: null,
        product: {},
        locationId: null,
        batchCode: null,
        expiryDate: null,
        note: null,
        initialInventory: 0,
        cogs: 0,
      },
      initialValues: {},
      expiryDateInvalid: false,
      products: [],
      supplierCy,
      getDateFormat,
    };
  },
  computed: {
    ...mapState('entities/products', ['stocktake']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_UPDATE_INVENTORY_ITEM);
    },
    me() {
      return this.loggedUser.distributor;
    },
    today() {
      return dayjs().startOf('day');
    },
    locations() {
      if (!this.stocktake.warehouse?.locations?.length) return [{ id: null, code: 'Default' }];
      return this.stocktake.warehouse.locations;
    },
    batchCodeValid() {
      return this.me.inventorySettings?.batchTracking ? !!this.values.batchCode?.trim() : true;
    },
    hasMadeChanges() {
      const current = JSON.stringify(this.values);
      const initial = JSON.stringify(this.initialValues);

      return current !== initial;
    },
    disabled() {
      return !this.batchCodeValid || !this.hasMadeChanges || this.expiryDateInvalid;
    },
    hasEditInventoryItemPermission() {
      return this.$permission.has('editInventoryItem');
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'fetchDistributorProducts',
      'distributorUpdateInventoryItem',
    ]),
    open(item) {
      const { note, expiryDate, ...rest } = item;
      this.values = {
        ...clone(rest),
        expiryDate: expiryDate ? dayjs(expiryDate) : null,
        note: note || null,
      };
      this.initialValues = {
        ...clone(rest),
        expiryDate: expiryDate ? dayjs(expiryDate) : null,
        note: note || null,
      };
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    async saveChanges() {
      try {
        await this.updateInventoryItem();
        this.$emit('success', this.values);
        this.close();
      } catch (err) {
        // do nothing
      }
    },
    onClose() {
      Object.keys(this.values).forEach((key) => {
        if (key === 'product') this.values[key] = {};
        else this.values[key] = null;
      });

      this.initialValues = {};
      this.expiryDateInvalid = false;
    },
    onInputChange(name, event) {
      this.values[name] = event;
    },
    onInvalidExpiryDate(value) {
      this.expiryDateInvalid = value;
    },
    async updateInventoryItem() {
      const { id, cogs, batchCode, expiryDate, initialInventory } = this.values;
      const data = {
        cogs,
        batchCode: batchCode?.trim() || null,
        expiryDate: expiryDate ? dayjs(expiryDate).format('YYYY-MM-DD') : null,
        initialInventory,
      };
      await this.distributorUpdateInventoryItem({ id, data });
      flash.success({ title: 'Inventory item successfully edited.' });
    },
  },
  async created() {
    const { data } = await this.fetchDistributorProducts({ sortBy: 'name' });
    this.products = [...data.data];
  },
};
</script>

<template>
  <ez-form-modal ref="modal" @close="onClose">
    <template #title>Edit Stock</template>
    <template #content>
      <div class="subheader">
        <p class="subheader__text">
          Choose what information you would like to change for the selected product at this
          location.
        </p>
        <hr />
      </div>

      <ez-entity-info class="selected-product" :imgUrl="values.product.image">
        <div class="product-info" :title="values.product.name">
          <span class="product-info__name">{{ values.product.name }}</span>
          <span class="product-info__sku">{{ values.product.sku }}</span>
        </div>
      </ez-entity-info>

      <ez-select
        class="mask-input mt-16"
        label="Location"
        nameField="code"
        :value="values.locationId"
        :selected="values.locationId"
        :options="locations"
        disabled
        isFullWidth
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.EDIT_STOCK_MODAL.BUTTON__SELECT_LOCATION"
      />
      <ez-input
        form-key=""
        name="batchCode"
        label="Batch ID"
        class="mask-input mt-16"
        placeholder="Enter Batch ID"
        :value="values.batchCode"
        :disabled="!hasEditInventoryItemPermission"
        @onChange="onInputChange('batchCode', $event)"
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.EDIT_STOCK_MODAL.INPUT__BATCH_CODE"
      />
      <ez-date-input
        form-key=""
        name="expiryDate"
        label="Expiry Date"
        :placeholder="getDateFormat()"
        :date-format="getDateFormat()"
        :class="['mask-input', 'mt-16', { 'empty-date': !values.expiryDate }]"
        :min="today"
        :disabled="!hasEditInventoryItemPermission"
        v-model="values.expiryDate"
        @onChange="onInputChange('expiryDate', $event)"
        @invalid="onInvalidExpiryDate"
        is-tooltip-error
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.EDIT_STOCK_MODAL.INPUT__EXPIRY_DATE"
      />
      <ez-mask-input
        form-key=""
        type="input"
        name="cogs"
        label="Cost of Goods Sold (COGS)"
        class="mask-input mt-16"
        :value="values.cogs || 0"
        :disabled="!hasEditInventoryItemPermission"
        :allow-negative-value="false"
        @input="onInputChange('cogs', $event)"
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.EDIT_STOCK_MODAL.INPUT__COGS"
      />
      <ez-textarea
        formKey=""
        name="note"
        label="Note"
        class="mask-input mt-16"
        placeholder="Leave a Note"
        :value="values.note"
        @onChange="onInputChange('note', $event)"
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.EDIT_STOCK_MODAL.TEXTAREA__NOTE"
      />
    </template>
    <template #footer>
      <ez-button
        type="link"
        @click="close"
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.EDIT_STOCK_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        :disabled="disabled"
        :isLoading="isLoading"
        @click="saveChanges"
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.EDIT_STOCK_MODAL.BUTTON__SAVE"
      >
        Save Changes
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.subheader {
  &__text {
    @include font-size(14px, 20px);
    margin-top: 0;
  }
}

.selected-product {
  padding: 16px 12px;
  border-radius: 3px;
  background-color: $color-gray-F5;

  .product-info {
    display: flex;
    flex-direction: column;

    &__name {
      @include font-size(14px, 20px);
      color: $color-gray-25;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__sku {
      @include font-size(12px, 14px);
      color: $color-gray-6C;
      font-weight: 500;
    }
  }
}

.mask-input {
  :deep() .mask-input__input {
    font-weight: normal;
  }

  &.empty-date :deep() .placeholder {
    color: $color-gray-6C;
  }

  :deep() .ez-select__dropdown .ez-option {
    @include font-size(14px);
  }
}
</style>
