<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import uuid from 'uuid/v4';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { clone } from '@/util/utils';
import { wizardListenerMixin } from '@/mixins/wizard';
import { LOADING_KEY } from '@/util/constants';

import EzTextarea from '@/components/ui/Textarea';
import EzLoader from '@/components/ui/Loader';
import EzButton from '@/components/ui/Button';
import EzTable from '@/components/ui/Table';
import EzInput from '@/components/ui/Input';
import flash from '@/components/ui/FlashMessage';
import Paper from '@/components/layout/WizardOverviewPaper.vue';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo';
import { STOCKTAKE_STEP_1, STOCKTAKE_STEP_2 } from './steps';

/**
 * Overview
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Overview',
  components: {
    EzTextarea,
    EzLoader,
    EzButton,
    EzTable,
    EzInput,
    Paper,
    VProductEntityInfo,
  },
  data() {
    return {
      users: [],
      note: null,
      addUserDisabled: false,
      productsListExpanded: false,
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/products', ['stocktake']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    warehouse() {
      return this.stocktake.warehouse;
    },
    selectedProducts() {
      return this.stocktake.selectedProducts;
    },
    loading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_CONDUCT_STOCKTAKE);
    },
    listProducts() {
      if (!this.productsListExpanded) return this.selectedProducts.slice(0, 6);
      return this.selectedProducts;
    },
    expandButtonCopy() {
      return this.productsListExpanded ? 'Collapse List' : 'Expand List';
    },
    expandButtonIcon() {
      return this.productsListExpanded ? 'angle-up' : 'angle-down';
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorConductStocktake']),
    ...mapMutations('entities/products', ['CLEAR_DRAFT', 'UPDATE_STOCKTAKE_DRAFT']),
    editWarehouse() {
      this.$emit('stepBack', STOCKTAKE_STEP_1);
    },
    editProducts() {
      this.$emit('stepBack', STOCKTAKE_STEP_2);
    },
    toggleListExpanded() {
      this.productsListExpanded = !this.productsListExpanded;
    },
    async onNextStep() {
      try {
        const data = {
          items: this.selectedProducts.map(i => ({
            itemId: i.id,
            quantity: i.quantity,
            batchCode: i.batchCode,
            expiryDate: i.expiryDate,
            note: i.note || null,
          })),
          warehouseId: this.warehouse.id,
          note: this.stocktake.note,
          users: this.stocktake.users
            .filter(u => u.id !== this.loggedUser.id)
            .map(u => u.name.trim())
            .filter(Boolean),
        };

        await this.distributorConductStocktake({ data });

        this.CLEAR_DRAFT();
        flash.success({ title: 'Stocktake conducted successfully.' });

        await this.$router.push({ name: 'distributor-products-stocktakes' });
      } catch (e) {
        // do nothing
      }
    },
    addUser() {
      this.users = [...this.users, { id: uuid(), name: '' }];
    },
    removeUser(idx) {
      this.users.splice(idx, 1);
    },
    onUserNameChange(idx, val) {
      this.users.splice(idx, 1, { ...this.users[idx], name: val.trim() });
    },
    onNoteChange(val) {
      this.note = val;
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
  },
  created() {
    this.enableNextStep();

    if (this.stocktake.users?.length) this.users = clone(this.stocktake.users);
    else this.users = [{ id: this.loggedUser.id, name: this.loggedUser.name }];

    this.note = this.stocktake.note || '';
  },
  watch: {
    users(val) {
      this.addUserDisabled = val.some(u => !u.name.trim());
      this.UPDATE_STOCKTAKE_DRAFT({ users: clone(val) });
    },
    note(val) {
      this.UPDATE_STOCKTAKE_DRAFT({ note: val });
    },
  },
};
</script>

<template>
  <div class="ez-inventory-overview">
    <paper>
      <template #title>WAREHOUSE</template>
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editWarehouse"
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.BUTTON__EDIT_WAREHOUSE"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <template>
        <div class="overview-warehouses">
          <div class="overview-warehouses__warehouse">
            <div class="warehouse-section">
              <p class="warehouse-section__label">Warehouse Name</p>
              <p
                class="warehouse-section__text"
                :data-cy="
                  supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.TEXT__WAREHOUSE_NAME
                "
              >
                {{ warehouse.name }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </paper>
    <paper class="products-section">
      <template #title>STOCKTAKE ({{ selectedProducts.length }})</template>
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editProducts"
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.BUTTON__EDIT_PRODUCTS"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <template>
        <ez-table
          class="products-table"
          v-if="listProducts"
          :data="listProducts"
          :columns="['name']"
          :headers="false"
          disableHover
        >
          <template #cell-name="{ row: { id, product } }">
            <v-product-entity-info
              :product="product"
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.TEXT__PRODUCT_NAME}-${id}`"
            />
          </template>
        </ez-table>
      </template>
      <template #footer>
        <div v-if="selectedProducts.length > 6" class="u-flex-h-center mt-16">
          <ez-button
            @click="toggleListExpanded"
            type="link"
            formType="button"
            :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.BUTTON__EXPAND"
          >
            <span>{{ expandButtonCopy }}</span>
            <font-awesome-icon class="ml-8" :icon="expandButtonIcon" />
          </ez-button>
        </div>
      </template>
    </paper>
    <paper class="users-section" v-if="users.length">
      <template #title>USERS</template>
      <template>
        <div class="users-section__user mt-12" v-for="(user, idx) in users" :key="user.id">
          <ez-input
            formKey=""
            label="Stocktaker"
            :name="`user-${user.id}`"
            :value="user.name"
            :disabled="!idx"
            @onChange="onUserNameChange(idx, $event)"
            :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.INPUT__USER_NAME}-${user.id}`"
          />
          <ez-button
            v-if="idx"
            class="users-section__remove"
            type="link"
            @click="removeUser(idx)"
            :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.BUTTON__REMOVE_USER}-${user.id}`"
          >
            <font-awesome-icon icon="times" /> Remove
          </ez-button>
        </div>

        <ez-button
          type="secondary"
          class="add-user-button mt-16"
          @click="addUser"
          isFullWidth
          :disabled="addUserDisabled"
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.BUTTON__ADD_USER"
        >
          <font-awesome-icon icon="plus" />
          Add User
        </ez-button>
      </template>
    </paper>
    <paper class="note-section">
      <template #title>NOTE</template>
      <template>
        <ez-textarea
          formKey=""
          label=""
          name="note"
          placeholder="Leave a Note (Optional)"
          :value="note"
          @onChange="onNoteChange"
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.OVERVIEW.TEXTAREA__NOTE"
        />
      </template>
    </paper>

    <ez-loader :show="loading" />
  </div>
</template>

<style lang="scss" scoped>
.overview-warehouses {
  &__warehouse {
    .warehouse-section {
      &__label {
        @include font-size(12px, 18px);
        margin: 0 0 4px;
        color: $color-gray-6C;
        font-weight: 600;
      }

      &__text {
        @include font-size(14px, 20px);
        margin: 0;
      }
    }
  }
}

.users-section {
  &__user {
    position: relative;
  }

  &__remove {
    @include font-size(12px, 18px);
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
  }

  .add-user-button:disabled {
    background-color: #f5f6fa;
    color: rgba($color-gray-6C, 0.48);
  }
}

.products-table tbody tr td {
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 0;

  :deep(.entity-info__text) {
    white-space: nowrap;
  }
}
</style>
