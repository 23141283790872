<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { clone, debounce } from '@/util/utils';
import { LOADING_KEY, UNIT_TYPE_FRACTIONAL, UNIT_TYPE_PACK } from '@/util/constants';

import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzFilterList from '@/components/ui/FilterList';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzButton from '@/components/ui/Button';
import EzBadge from '@/components/ui/Badge';
import EzTable from '@/components/ui/Table';
import EzInput from '@/components/ui/Input';
import VWarehouseLocation from '@/components/v3/elements/VWarehouseLocation';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import AddNewStockModal from './AddNewStockModal.vue';
import EditStockModal from './EditStockModal.vue';

/**
 * Products
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Products',
  components: {
    VDataWithInfo,
    EzMaskInputSimple,
    EzEntityInfo,
    EzFilterList,
    EzLoadMore,
    EzSpinner,
    EzButton,
    EzBadge,
    EzTable,
    EzInput,
    VWarehouseLocation,
    EzButtonDropdown,
    AddNewStockModal,
    EditStockModal,
  },
  data() {
    return {
      products: [],
      productNotes: {},
      selectedProducts: [],
      meta: {},
      isInvalidQuantity: {},
      filters: {
        term: null,
      },
      packUnitType: UNIT_TYPE_PACK,
      dayjs,
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/products', ['stocktake']),
    ...mapGetters('loading', ['getLoading']),
    loading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_OVERVIEW);
    },
    loadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_PRODUCT_INVENTORY_OVERVIEW);
    },
    warehouse() {
      return this.stocktake.warehouse;
    },
    hasFilters() {
      return Object.values(this.filters).some(v => !!v);
    },
    noProductsFound() {
      return !this.loading && !this.loadingMore && !this.products.length;
    },
    nextStepDisabled() {
      return Object.values(this.isInvalidQuantity).some(e => e) || !this.selectedProducts.length;
    },
  },
  methods: {
    ...mapActions('entities/products', ['distributorFetchProductInventoryOverview']),
    ...mapMutations('entities/products', ['UPDATE_STOCKTAKE_DRAFT']),
    isFractional(row) {
      return row.product.orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    increment(row) {
      return this.isFractional(row) ? 0.01 : 1;
    },
    canEditStock(row) {
      return this.isSelected(row.id);
    },
    onInputChange(key, row, val) {
      if (key !== 'quantity') {
        this.updateSelectedProduct(row.id, { [key]: val });
        return;
      }

      const currentQuantity = this.findSelected(row.id, 'quantity');
      if (!val && !currentQuantity) return;

      this.updateSelectedProductQuantity(row, val || 0);
    },
    onInvalidQuantity(row, val) {
      this.isInvalidQuantity = {
        ...this.isInvalidQuantity,
        [row.id]: val,
      };
    },
    updateSelectedProductQuantity(row, val) {
      if (!this.isSelected(row.id)) this.selectProduct(row, val);
      this.updateSelectedProduct(row.id, { quantity: val });
    },
    isSelected(id) {
      return !!this.findSelected(id);
    },
    findSelected(id, key = '') {
      const item = this.selectedProducts.find(p => p.id === id);
      if (!key) return item;

      return item?.[key];
    },
    selectProduct(row, quantity = 0) {
      if (this.isSelected(row.id)) return;

      const { id, cogs, initialInventory, batchCode, expiryDate, product, location } = row;
      this.selectedProducts.push({
        id,
        cogs,
        quantity,
        batchCode,
        expiryDate,
        initialInventory,
        note: this.productNotes[id] || null,
        locationId: location?.id || null,
        product: {
          id: product.id,
          name: product.name,
          sku: product.sku,
          image: product.image,
        },
      });
    },
    unselectProduct(id) {
      const { [id]: removed, ...rest } = this.isInvalidQuantity;
      this.isInvalidQuantity = rest;

      const idx = this.selectedProducts.findIndex(p => p.id === id);
      this.selectedProducts.splice(idx, 1);
    },
    updateSelectedProduct(id, payload) {
      const idx = this.selectedProducts.findIndex(p => p.id === id);
      this.selectedProducts.splice(idx, 1, { ...this.selectedProducts[idx], ...payload });
    },
    async resetFilters() {
      this.filters.term = null;
      this.products = [];
      this.meta = {};
      await this.fetchProducts();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') this.filters.term = event || null;
      this.products = [];
      this.meta = {};
      await this.fetchProducts();
    }, 300),
    onNextStep() {
      this.UPDATE_STOCKTAKE_DRAFT({ selectedProducts: clone(this.selectedProducts) });
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    async fetchProducts(loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_OVERVIEW) {
      const query = {
        limit: 10,
        sortBy: 'name',
        warehouseId: this.warehouse.id,
        includeNegative: true,
        includeEmptyInitial: true,
        ...this.filters,
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
      };

      const { data } = await this.distributorFetchProductInventoryOverview({ query, loadingKey });

      if (loadingKey === LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_OVERVIEW) {
        this.products = clone(data.data);
      } else {
        this.products = clone([...this.products, ...data.data]);
      }
      this.meta = clone(data.meta);
    },
    async loadMoreProducts() {
      await this.fetchProducts(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_PRODUCT_INVENTORY_OVERVIEW);
    },
    openAddNewStockModal() {
      this.$refs.addNewStockModal.open();
    },
    openEditStockModal(row) {
      this.$refs.editStockModal.open(this.findSelected(row.id));
    },
    async onAddNewStockSuccess() {
      await this.resetFilters();
    },
    async onEditStockSuccess(values) {
      const idx = this.selectedProducts.findIndex(p => p.id === values.id);
      this.selectedProducts.splice(idx, 1, { ...this.selectedProducts[idx], ...values });
      this.productNotes = { ...this.productNotes, [values.id]: values.note };

      await this.resetFilters();
    },
  },
  watch: {
    nextStepDisabled: {
      immediate: true,
      handler(val) {
        if (val) this.disableNextStep();
        else this.enableNextStep();
      },
    },
  },
  async created() {
    if (this.stocktake.selectedProducts) {
      this.selectedProducts = clone(this.stocktake.selectedProducts);
    }
    await this.$nextTick();
    await this.fetchProducts();
  },
};
</script>

<template>
  <div class="products-step">
    <div class="subheader mb-24 u-flex-space">
      <ez-filter-list :filters="filters" @resetFilter="resetFilters" @filterUpdated="updateFilters">
        <ez-input
          formKey="filters"
          label="search"
          name="search"
          class="search"
          placeholder="Search for a Product"
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.INPUT__SEARCH"
        >
          <template #suffix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </ez-filter-list>

      <p class="subheader__selected-count">
        Products selected:
        <ez-badge type="blue" :count="selectedProducts.length || 0" showEveryNumber />
      </p>

      <div class="subheader__add-new">
        <ez-button
          @click="openAddNewStockModal"
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__ADD_STOCK"
        >
          Add New Stock
        </ez-button>
      </div>
    </div>

    <ez-table
      class="products-table"
      disableHover
      :loading="loading"
      :data="products"
      :columns="[
        'name',
        'location',
        'batchCode',
        'currentInventory',
        'expiryDate',
        'quantity',
        'unit',
        'cogs',
        'actions',
      ]"
      :headers="{
        name: () => 'Product',
        currentInventory: () => 'Stock',
        batchCode: () => 'Batch ID',
        quantity: () => 'Actual Inventory',
        cogs: () => 'COGS',
      }"
      :columnProps="{
        name: { class: 'name-cell' },
        location: { class: 'medium-cell location-cell' },
        batchCode: { class: 'batchCode-cell' },
        expiryDate: { class: 'medium-cell' },
        quantity: { class: 'quantity-cell medium-cell' },
        unit: { class: 'medium-cell unit-cell' },
        cogs: { class: 'small-cell text-right-cell' },
        actions: { class: 'actions-cell' },
        currentInventory: { class: 'currentInventory-cell' },
      }"
    >
      <template
        #cell-name="{
          row: {
            product: { image, name, sku },
          },
        }"
      >
        <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="image">
          <div class="product-info" :title="name">
            <span>{{ name }}</span>
            <span class="product-info-secondary">{{ sku }}</span>
          </div>
        </ez-entity-info>
      </template>

      <template #cell-batchCode="{ row: { batchCode } }">
        <span v-if="batchCode" :title="batchCode">{{ batchCode }}</span>
        <span v-else>-</span>
      </template>

      <template #cell-location="{ row: { location } }">
        <v-warehouse-location :data="location ? [location.code] : []" />
      </template>

      <template #cell-expiryDate="{ row: { expiryDate } }">
        <span v-if="expiryDate">{{ dayjs(expiryDate) | date }}</span>
        <span v-else>-</span>
      </template>

      <template #cell-quantity="{ row }">
        <ez-mask-input-simple
          formKey=""
          name="quantity"
          :precision="isFractional(row) ? 2 : 0"
          :value="findSelected(row.id, 'quantity')"
          :minValue="0"
          :isInvalid="isInvalidQuantity[row.id]"
          @input="onInputChange('quantity', row, $event)"
          @invalid="onInvalidQuantity(row, $event)"
          @click.stop
          :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.INPUT__INVENTORY}-${row.id}`"
          :has-currency="false"
        />
      </template>

      <template
        #cell-unit="{
          row: {
            product: { orderingUnit },
          },
        }"
      >
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="
              orderingUnit.type === packUnitType
                ? 'The quantity of pack unit products ' + 'is being managed per single unit.'
                : ''
            "
            :show-underline="orderingUnit.type === packUnitType"
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>

      <template #cell-cogs="{ row }">
        {{ row.cogs || 0 | price }}
      </template>

      <template #cell-actions="{ row }">
        <ez-button
          v-if="isSelected(row.id)"
          type="green"
          @click="unselectProduct(row.id)"
          :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__DESELECT}-${row.id}`"
        >
          Selected
        </ez-button>
        <ez-button
          v-else
          type="secondary"
          @click="selectProduct(row)"
          :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__SELECT}-${row.id}`"
        >
          Select
        </ez-button>
        <ez-button-dropdown
          buttonType="secondary"
          class="ml-16"
          :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__OPTIONS}-${row.id}`"
        >
          <template #icon>
            <font-awesome-icon icon="ellipsis-h" />
          </template>
          <template #dropdown>
            <ez-button
              type="link"
              :disabled="!canEditStock(row)"
              @click="openEditStockModal(row)"
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__EDIT_STOCK}-${row.id}`"
            >
              Edit Stock
            </ez-button>
          </template>
        </ez-button-dropdown>
      </template>
    </ez-table>

    <div v-if="loadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="!loadingMore && meta.nextId" @loadMore="loadMoreProducts" />

    <add-new-stock-modal ref="addNewStockModal" @success="onAddNewStockSuccess" />
    <edit-stock-modal ref="editStockModal" @success="onEditStockSuccess" />
  </div>
</template>

<style lang="scss" scoped>
.products-step {
  margin-top: 64px;
}

.subheader {
  border-bottom: 1px solid #eceef5;
  padding: 14px 0;

  & > * {
    flex: 1 0 33.33333%;
  }

  &__selected-count {
    @include font-size(14px, 20px);
    color: $color-gray-6C;
    text-align: center;
    margin: 0;
  }

  &__add-new {
    display: flex;
    justify-content: flex-end;
  }
}

.products-table {
  .unit-cell {
    overflow: hidden;
  }

  .currentInventory-cell {
    width: 96px;
  }
  .name-cell {
    .product-info {
      display: flex;
      flex-direction: column;

      .product-info-secondary {
        display: block;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .location-cell {
    :deep() .dashed-underline {
      border-bottom: 2px dotted rgba($color-gray-6C, 0.64);
      @include font-size(14px);
      font-weight: 400;

      &--out_of_stock {
        border-color: rgba($color-primary-red, 0.64);
      }

      &--without {
        border: none;
      }
    }
  }

  .quantity-cell {
    overflow: visible;
  }

  .actions-cell {
    width: 152px;
    overflow: visible;

    .button--link:disabled {
      color: $color-gray-6C;
      background-color: transparent;
    }
  }

  .batchCode-cell {
    width: 96px;
  }
}

:deep() .ez-empty-state__image {
  max-width: 256px;

  img {
    width: 256px;
    height: 118px;
  }
}

:deep() .mask-input.mark-input--inline {
  .mask-input__input {
    font-weight: normal;
    color: $color-gray-25;
    text-align: center;
  }
}
</style>
