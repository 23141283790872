<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { clone, debounce, getContextId } from '@/util/utils';
import { LOADING_KEY, UNIT_TYPE_PACK } from '@/util/constants';

import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzFilterList from '@/components/ui/FilterList';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzButton from '@/components/ui/Button';
import EzBadge from '@/components/ui/Badge';
import EzTable from '@/components/ui/Table';
import EzInput from '@/components/ui/Input';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import VTag from '@/components/v3/elements/VTag';
import EzCsvUploadModal from '@/components/ui/Modal/EzCsvUploadModal.vue';
import AddNewStockModal from './AddNewStockModal.vue';

/**
 * Products
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Products',
  components: {
    VDataWithInfo,
    EzMaskInputSimple,
    EzEntityInfo,
    EzFilterList,
    EzLoadMore,
    EzSpinner,
    EzButton,
    EzBadge,
    EzTable,
    EzInput,
    VTag,
    AddNewStockModal,
    EzCsvUploadModal,
  },
  props: {
    stocktakeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      products: [],
      meta: { page: 0 },
      isInvalidQuantity: {},
      filters: {
        term: null,
      },
      packUnitType: UNIT_TYPE_PACK,
      dayjs,
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/products', ['venueStocktake']),
    ...mapGetters('loading', ['getLoading']),
    venueId() {
      return getContextId();
    },
    loading() {
      return this.getLoading(LOADING_KEY.VENUE_FETCH_SUPPLIER_PRODUCTS);
    },
    loadingMore() {
      return this.getLoading(LOADING_KEY.VENUE_FETCH_MORE_ALL_SUPPLIERS_PRODUCTS);
    },
    location() {
      return this.venueStocktake.location;
    },
    hasFilters() {
      return Object.values(this.filters).some(v => !!v);
    },
    noProductsFound() {
      return !this.loading && !this.loadingMore && !this.products.length;
    },
    nextStepDisabled() {
      return (
        Object.values(this.isInvalidQuantity).some(e => e) ||
        !this.products.filter(item => item.quantity || item.quantity === 0).length
      );
    },
    selectedProducts() {
      return this.products.filter(item => item.quantity || item.quantity === 0);
    },
  },
  methods: {
    ...mapActions('entities/venues', [
      'venueFetchProductsByLocation',
      'venueUpdateProductQuantity',
      'venueImportStocktakeCSV',
    ]),
    ...mapMutations('entities/products', ['UPDATE_VENUE_STOCKTAKE_DRAFT']),
    onInputChange: debounce(async function deb(key, row, val) {
      const productIndex = this.products.findIndex(item => item.id === row.id);
      const currentQuantity = this.products[productIndex].quantity;
      if (val === null && (currentQuantity === null || currentQuantity === undefined)) return;
      if (val === null || currentQuantity !== val) {
        this.updateProductQuantity(row, val);
        await this.venueUpdateProductQuantity({
          venueId: this.venueId,
          stocktakeId: this.stocktakeId,
          locationId: this.location.id,
          body: {
            productId: row.product.id,
            position: row.position,
            quantity: val,
          },
        });
      }
    }, 300),
    onInvalidQuantity(row, val) {
      this.isInvalidQuantity = {
        ...this.isInvalidQuantity,
        [row.id]: val,
      };
    },
    isSelected(id) {
      const productIndex = this.products.findIndex(item => item.id === id);
      return this.products[productIndex].quantity || this.products[productIndex].quantity === 0;
    },
    updateProductQuantity(row, quantity) {
      const productIndex = this.products.findIndex(item => item.id === row.id);
      this.products[productIndex].quantity = quantity;
    },
    async selectProductOnClick(row, quantity = 0) {
      this.updateProductQuantity(row, quantity);
      await this.venueUpdateProductQuantity({
        venueId: this.venueId,
        stocktakeId: this.stocktakeId,
        locationId: this.location.id,
        body: {
          productId: row.product.id,
          position: row.position,
          quantity,
        },
      });
    },
    async unselectProductOnClick(row) {
      this.updateProductQuantity(row, null);
      await this.venueUpdateProductQuantity({
        venueId: this.venueId,
        stocktakeId: this.stocktakeId,
        locationId: this.location.id,
        body: {
          productId: row.product.id,
          position: row.position,
          quantity: null,
        },
      });
    },
    async resetFilters() {
      this.filters.term = null;
      this.products = [];
      this.meta = { page: 0 };
      await this.fetchProducts();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') this.filters.term = event || null;
      this.products = [];
      this.meta = { page: 0 };
      await this.fetchProducts();
    }, 300),
    onNextStep() {
      this.UPDATE_VENUE_STOCKTAKE_DRAFT({ selectedProducts: clone(this.selectedProducts) });
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    async fetchProducts(loadingKey = LOADING_KEY.VENUE_FETCH_SUPPLIER_PRODUCTS) {
      const { data } = await this.venueFetchProductsByLocation({
        venueId: this.venueId,
        stocktakeId: this.stocktakeId,
        locationId: this.location.id,
        ...(this.filters.term ? { term: this.filters.term } : {}),
        page: this.meta.page + 1,
        loadingKey,
      });

      if (loadingKey === LOADING_KEY.VENUE_FETCH_SUPPLIER_PRODUCTS) {
        this.products = data.data;
      } else {
        this.products = [...this.products, ...data.data];
      }
      this.meta = data.meta;
    },
    async loadMoreProducts() {
      await this.fetchProducts(LOADING_KEY.VENUE_FETCH_MORE_ALL_SUPPLIERS_PRODUCTS);
    },
    openAddNewStockModal() {
      this.$refs.addNewStockModal.open();
    },
    async onAddNewStockSuccess(event) {
      const { product } = event;
      event.positions.forEach(pos => {
        const productObj = {
          id: product.id,
          lastCount: null,
          product,
          position: pos,
          quantity: null,
        };
        const firstProduct = this.products[0];
        const lastProduct = this.products[this.products.length - 1];
        if (+firstProduct.position > +pos) {
          this.products = [productObj, ...this.products];
        } else if (
          +lastProduct.position > +pos ||
          (+lastProduct.position === +pos &&
            lastProduct.product.name.toLowerCase() > product.name.toLowerCase())
        ) {
          this.products.push(productObj);
          this.products = this.products.sort((a, b) => {
            if (+a.position < +b.position) return -1;
            if (+a.position < +b.position) return 1;
            if (+a.position === +b.position) {
              if (a.product.name === b.product.name) return 0;
              return a.product.name.toLowerCase() > b.product.name.toLowerCase() ? 1 : -1;
            }
            return 0;
          });
        }
      });
    },
    openImportCSVModal() {
      this.$refs.csvUploadModal.open();
    },
    async onUploadFinished() {
      await this.fetchProducts();
    },
    uploadAction(data) {
      return this.venueImportStocktakeCSV({
        body: data,
        venueId: this.venueId,
        stocktakeId: this.stocktakeId,
        locationId: this.location.id,
      });
    },
    onMinusClick(product) {
      this.updateProductQuantity(product, product.quantity - 1);
    },
    onPlusClick(product) {
      this.updateProductQuantity(product, product.quantity + 1);
    },
    goToNextProduct(id) {
      const productIndex = this.products.findIndex(item => item.id === id);
      if (this.products[productIndex + 1]) {
        const nextId = this.products[productIndex + 1].id;
        const prodQuantityEl = document.querySelector(
          `#product-item-${nextId} input[name="quantity"]`,
        );
        if (prodQuantityEl) prodQuantityEl.focus();
      }
    },
    onEnter(event, id) {
      if (event.keyCode === 13) {
        this.goToNextProduct(id);
      }
    },
  },
  watch: {
    nextStepDisabled: {
      immediate: true,
      handler(val) {
        if (val) this.disableNextStep();
        else this.enableNextStep();
      },
    },
  },
  async created() {
    await this.fetchProducts();
  },
};
</script>

<template>
  <div class="products-step">
    <div class="subheader u-flex-space">
      <ez-filter-list :filters="filters" @resetFilter="resetFilters" @filterUpdated="updateFilters">
        <ez-input
          formKey="filters"
          label="search"
          name="search"
          class="search"
          placeholder="Search for a Product"
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.INPUT__SEARCH"
        >
          <template #suffix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </ez-filter-list>

      <p class="subheader__selected-count">
        Products selected:
        <ez-badge type="blue" :count="selectedProducts.length || 0" showEveryNumber />
      </p>

      <div class="subheader__add-new">
        <ez-button @click="openImportCSVModal" class="mr-12" type="secondary">
          Import CSV
        </ez-button>
        <ez-button
          @click="openAddNewStockModal"
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__ADD_STOCK"
        >
          Add New
        </ez-button>
      </div>
    </div>

    <ez-table
      class="products-table"
      disableHover
      :loading="loading"
      :data="products"
      :hasMinHeight="false"
      :columns="['name', 'unit', 'order', 'lastCount', 'quantity', 'actions']"
      :headers="{
        name: () => 'Product',
        quantity: () => 'Current Count',
      }"
      :columnProps="{
        name: { class: 'name-cell' },
        quantity: { class: 'quantity-cell medium-cell' },
        unit: { class: 'medium-cell unit-cell' },
        actions: { class: 'actions-cell' },
        order: { class: 'small-cell' },
        lastCount: { class: 'medium-cell' },
      }"
    >
      <template
        #cell-name="{
          row: {
            product: { image, name, sku, category },
          },
        }"
      >
        <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="image">
          <div class="product-info" :title="name">
            <span>
              {{ name }}
            </span>
            <span class="product-info-secondary">
              <span class="product-info-secondary--category">
                {{ category | categoryWithParent }}
              </span>
              <span v-if="sku" class="product-info-secondary--sku"> &#8226; {{ sku }} </span>
            </span>
          </div>
        </ez-entity-info>
      </template>

      <template #cell-order="{ row }">
        <v-tag color="#983BBD" backgroundColor="#EEDFF4" class="ml-4">{{ row.position }}</v-tag>
      </template>

      <template #cell-lastCount="{ row }">
        <span v-if="row.lastCount">{{ row.lastCount }}</span>
        <span v-else-if="row.lastCount === 0">0</span>
        <span v-else>-</span>
      </template>

      <template #cell-quantity="{ row }">
        <ez-mask-input-simple
          formKey=""
          name="quantity"
          :precision="2"
          :value="row.quantity ?? null"
          :minValue="0"
          :isInvalid="isInvalidQuantity[row.id]"
          @input="onInputChange('quantity', row, $event)"
          @invalid="onInvalidQuantity(row, $event)"
          @click.stop
          :has-currency="false"
        />
      </template>

      <template
        #cell-unit="{
          row: {
            product: { orderingUnit },
          },
        }"
      >
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="
              orderingUnit.type === packUnitType
                ? 'The quantity of pack unit products ' + 'is being managed per single unit.'
                : ''
            "
            :show-underline="orderingUnit.type === packUnitType"
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>

      <template #cell-actions="{ row }">
        <ez-button
          v-if="isSelected(row.id)"
          type="green"
          @click="unselectProductOnClick(row, true)"
          :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__DESELECT}-${row.id}`"
        >
          Counted
        </ez-button>
        <ez-button
          v-else
          type="secondary"
          @click="selectProductOnClick(row, 0)"
          :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.CONDUCT_STOCKTAKE.STOCKTAKING.BUTTON__SELECT}-${row.id}`"
        >
          Count
        </ez-button>
      </template>
    </ez-table>

    <div class="mobile-products-list">
      <hr class="mb-8 mt-8" />
      <div
        v-for="product in products"
        :key="product.id"
        class="product-item"
        :id="`product-item-${product.id}`"
      >
        <div class="first-row">
          <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="product.product.image">
            <div class="product-info" :title="product.product.name">
              <span>
                {{ product.product.name }}
              </span>
              <span class="product-info-secondary">
                <span class="product-info-secondary--category">
                  {{ product.product.distributor.name }}
                </span>
              </span>
            </div>
          </ez-entity-info>
          <div class="product-quantity">
            <span>{{ product.quantity ?? 0 }}</span>
            <span class="product-info-secondary--category">
              {{ product.product.orderingUnit.label }}
            </span>
          </div>
        </div>
        <hr />
        <div class="second-row" @keydown="onEnter($event, product.id)">
          <div class="quantity-input">
            <button
              class="increment"
              type="button"
              :disabled="!product.quantity"
              @click="onMinusClick(product)"
            >
              <font-awesome-icon size="xs" icon="minus" />
            </button>
            <ez-mask-input-simple
              class="ml-4 mr-4"
              formKey=""
              name="quantity"
              :precision="2"
              :value="product.quantity ?? null"
              :minValue="0"
              :isInvalid="isInvalidQuantity[product.id]"
              @input="onInputChange('quantity', product, $event)"
              @invalid="onInvalidQuantity(product, $event)"
              @click.stop
              :has-currency="false"
            />
            <button class="increment" type="button" @click="onPlusClick(product)">
              <font-awesome-icon size="xs" icon="plus" />
            </button>
          </div>
          <ez-button :disabled="product.quantity === null" @click="goToNextProduct(product.id)"
            >Next</ez-button
          >
        </div>
      </div>
    </div>

    <div v-if="loadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="!loadingMore && meta.page < meta.lastPage" @loadMore="loadMoreProducts" />

    <add-new-stock-modal ref="addNewStockModal" @success="onAddNewStockSuccess" />

    <ez-csv-upload-modal
      ref="csvUploadModal"
      :uploadCSV="uploadAction"
      :onUploadFinished="onUploadFinished"
      venue-stocktake
      :stocktakeTemplateUrl="`venues/${venueId}/stocktakes/${stocktakeId}/locations/${location.id}/import-template`"
    />
  </div>
</template>

<style lang="scss" scoped>
$input-button-bg-color: #f5f6fa;

.products-step {
  margin-top: 64px;
}

.subheader {
  border-bottom: 1px solid #eceef5;
  padding: 14px 0;
  margin-bottom: 24px;

  & > * {
    flex: 1 0 33.33333%;
  }

  &__selected-count {
    @include font-size(14px, 20px);
    color: $color-gray-6C;
    text-align: center;
    margin: 0;
  }

  &__add-new {
    display: flex;
    justify-content: flex-end;
  }
}
.mobile-products-list {
  display: none;
  padding-bottom: 64px;

  .product-item {
    padding: 8px 0;
    border-bottom: 1px solid #eceef5;
    hr {
      margin: 8px 0;
    }
  }

  .first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.5;

    .entity-info {
      align-items: center;
      min-width: 0;
      flex-shrink: 1;
    }

    .product-quantity {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: $color-gray-6C;
      font-size: 14px;
      flex-shrink: 0;

      .product-info-secondary--category {
        font-size: 12px;
      }
    }
  }

  .second-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .quantity-input {
      display: flex;
      align-items: center;

      :deep() .mask-input-wrapper .mask-input .mask-input__input {
        border-radius: 0;
        font-size: 16px;
      }
    }
  }

  .product-info {
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;

    .product-info-secondary {
      display: block;
      font-size: 12px;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  button.increment {
    display: flex;
    align-items: center;
    justify-content: center;
    @extend %button-reset;
    flex-shrink: 0;
    @include size(40px);
    background-color: $input-button-bg-color;
    border-radius: 50%;
    color: $color-gray-6C;

    &:hover {
      cursor: pointer;
      background-color: #e9ebf2;
    }
  }
}
.products-table {
  .v-tag {
    font-weight: bold;
  }
  .unit-cell {
    overflow: hidden;
  }

  .name-cell {
    .product-info {
      display: flex;
      flex-direction: column;

      .product-info-secondary {
        display: block;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .quantity-cell {
    overflow: visible;
  }

  .actions-cell {
    width: 152px;
    overflow: visible;

    .button--link:disabled {
      color: $color-gray-6C;
      background-color: transparent;
    }
  }
}

:deep() .ez-empty-state__image {
  max-width: 256px;

  img {
    width: 256px;
    height: 118px;
  }
}

:deep() .mask-input.mark-input--inline {
  .mask-input__input {
    font-weight: normal;
    color: $color-gray-25;
    text-align: center;
  }
}

@media (max-width: 768px) {
  :deep() .products-table {
    display: none;
  }

  .subheader {
    border-bottom: none;
    margin-bottom: 0;
    .search.input-group {
      width: 100%;
    }

    &__selected-count {
      display: none;
    }

    &__add-new {
      display: none;
    }
  }
  .products-step {
    margin-top: 0;
    .mobile-products-list {
      display: block;
    }
  }
}
</style>
